.CalcSection {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: auto,auto;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background-color: #cef5ed;
  }
  
  .CalcTitle { grid-area: 1 / 1 / 2 / 3;   text-align: center; color: #ffffff; background-color: #09d3ac;}
  .CalcTitleL { grid-area: 2 / 1 / 3 / 2; }
  .CalcTitleR { grid-area: 2 / 2 / 3 / 3; }
  .CalcArea { grid-area: 2 / 1 / 3 / 3; }

  .CalcDelivery {
    background-color: #ffd9cc;
  }

  .CalcDeliveryTitle {
    background-color: #ff794d
    ;
  }

  .CalcCharging {
    background-color: #f8ed62;
  }

  .CalcChargingTitle {
    background-color: #dab600;
  }

  .CalcReport {
    background-color: #b0aeae;
  }

  .CalcReportTitle {
    background-color: #696969;
  }

  .SideBySide {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }

  .FormArea {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
  .div1 { grid-area: 1 / 1 / 2 / 2; }
  .div2 { grid-area: 1 / 2 / 2 / 3; }
  .div3 { grid-area: 1 / 3 / 2 / 4; }

  .SectionArea {
    padding-left: 5px;
  }

  .InlineFields { 
    display: inline-block;
    width: 33%;
   }


.App {
  font-size: calc(10px + 2vmin);
}

.App-logo {
  width: 30vw ;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(54, 53, 53);
}

.App-link {
  color: #09d3ac;
}

.ReadOnlyInput {
  -webkit-writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: initial;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
  -webkit-appearance: textfield;
  background-color: white;
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: Arial;
  padding: 2px 0px;
  border-width: 2px;
  border-style: inset;
  border-color: initial;
  border-image: initial;  
  border-color: #676767;
  width: 50%;
}

.FieldWidth90 {
  width: 90%;
}

.showlogin {
  visibility: visible;
}

.hidelogin {
  visibility: hidden;
}

.addtopmargin {
  margin-top: 20px;
}

.gridcenter {
  justify-items: center;
}

.navlink {
  color: hsla(0,0%,100%,.5);
  text-decoration: none;
}

a.navlink:hover {
  color: #ffffff;
  text-decoration: none;
}

.navbrandTitlelink {
  color: hsla(0,0%,90%,1);
  text-decoration: none;
}

a.navbrandTitlelink:hover {
  color: #ffffff;
  text-decoration: none;
}

/* nav item active class */
.active {
  color: #ffffff;
}